import axios from "axios";
import "./App.scss";
import { useState } from "react";
import { Table } from "react-bootstrap";
import SearchIcon from "@material-ui/icons/Search";
function App() {
  const [Datas, setdatas] = useState([]);
  const [intrest, setintrest] = useState({});
  const getData = async () => {
    axios
      .get(
        `https://graph.facebook.com/search?type=adinterest&q=[${intrest}]&limit=5000&locale=en_US&access_token=761569234683252%7CcHSAT-wY_kaqWNKjHtcmRKqougs`
      )
      .then((data) => {
        setdatas(data.data.data);
      });
  };

  console.log(Datas.length);
  return (
    <>
      <div class="top md:flex block py-6 px-10 text-white">
        <img src="./logo.png" className="width-auto" />
        <h3 className="md:text-3xl text-xl">
          Facebook Ad Interest Finder
          <span className="text-red-500 text-sm md:text-xl ">
            by Marketing Moody
          </span>
        </h3>
      </div>
      <div className="bo py-12 px-12">
        <div className="container text-center mx-auto md:w-1/2 bg-white rounded shadow py-8 px-10">
          <h1>Search Interest </h1>
          <input
            type="text"
            onChange={(e) => {
              setintrest(e.target.value);
            }}
            className="bg-gray-100 mt-6 rounded p-2 w-2/3"
            placeholder="Interest"
          />

          <button
            onClick={getData}
            className="p-2 ml-2 rounded hover:bg-black bg-red-500 text-white md:mt-0 mt-4"
          >
            <SearchIcon />
          </button>
        </div>
      </div>

      {Datas && (
        <>
          <div className="container mx-auto px-24  mt-2">
            <Table striped bordered hover size="sm" responsive>
              <thead>
                <tr>
                  <th>Intrests</th>
                  <th>Audience Min</th>
                  <th>Audience Max</th>
                  <th>Topics</th>
                </tr>
              </thead>
              <tbody>
                <>
                  {Datas.map((data) => {
                    return (
                      <>
                        <tr key={data.id}>
                          <td>{data.name}</td>
                          <td>{data.audience_size_lower_bound}</td>
                          <td>{data.audience_size_upper_bound}</td>
                          <td>{data.topic}</td>
                        </tr>
                      </>
                    );
                  })}
                </>
              </tbody>
            </Table>
          </div>
        </>
      )}
    </>
  );
}

export default App;
